/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import withIntegrationCardContext from 'hoc/withIntegrationCardContext';
import Slider from 'react-slick';

const Drift = ({ sliderSettings }) => {
  const { img1 } = useStaticQuery(graphql`
    query {
      img1: file(relativePath: { eq: "marketplace/drift/1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const photos = [
    {
      img: img1.childImageSharp.fluid,
      alt: 'Drift Integration',
      title: 'Integrate Drift with LiveSession',
    },
  ];
  return (
    <>
      <Slider {...sliderSettings(photos)} className="without-dots">
        <div>
          <Img fluid={photos[0].img} alt={photos[0].alt} title={photos[0].title} />
        </div>
      </Slider>
      <div className="section">
        <h2>Overview</h2>
        <p>
          Drift is a live chat app for marketing, sales, and customer service teams. It has a wide
          range of applications, and you can get even more from it if you connect it with other
          tools, such as LiveSession. Our integration adds LiveSession recording links to contact
          profiles in Drift.
        </p>
      </div>
      <div className="section">
        <h2>Benefits</h2>
        <ul className="checkmarks">
          <li>See how the customer used your website</li>
          <li>Find spots for improvement</li>
          <li>Access customer session replay from Drift</li>
        </ul>
      </div>
      <h2>How to install</h2>
      <p>
        Just copy and paste a piece of code, as described in our{' '}
        <a href="/help/drift-integration/">help center</a>.
      </p>
    </>
  );
};

export default withIntegrationCardContext(Drift);

Drift.propTypes = {
  sliderSettings: PropTypes.func.isRequired,
};
